import React from "react";
import { FullWidth } from "../fullWidth/FullWidth";
import "./Partners.scss";

const list = [
  {
    label: "Cares of Washington",
    href: "https://www.caresofwa.org/",
  },
  {
    label: "Comunidad Latina de Vashon",
    href: "https://www.facebook.com/ComunidadLatinaVashon/",
  },
  {
    label: "Congolese Integration Network",
    href: "https://cinseattle.org/",
  },
  {
    label: "DOVE Dignity, Opportunity, Voice & Empowerment ",
    href: "https://www.vashondoveproject.org/",
  },
  {
    label: "East African Community Services",
    href: "https://eastafricancs.org/?fbclid=IwAR2FVZKLbfx4B8C478-SSE0QZbk0FYebaE8dRtw63XG4YJAl2wXZ3BTyLDw",
  },
  {
    label: "Empowering Youth & Families Outreach",
    href: "https://www.eyfo.org",
  },
  {
    label: "Eritrean Association of Greater Seattle",
    href: "https://eritreanassociation.org/",
  },
  {
    label: "Families of Color Seattle",
    href: "https://www.focseattle.org/",
  },
  {
    label: "Guides.vote",
    href: "https://guides.vote",
  },
  {
    label: "Khmer Community South King County",
    href: "https://www.kcskc.org",
  },
  {
    label: "Kindering",
    href: "https://kindering.org",
  },
  {
    label: "King County Best Starts for Kids",
    href: "https://kingcounty.gov/depts/community-human-services/initiatives/best-starts-for-kids.aspx",
  },
  {
    label: "King County Department of Community and Human Services",
    href: "https://kingcounty.gov/en/dept/dchs/about-king-county/about-dchs/about",
  },
  {
    label: "King County Veterans, Seniors & Human Services Levy",
    href: "https://kingcounty.gov/depts/community-human-services/initiatives/levy.aspx",
  },
  {
    label: "Open Arms Perinatal",
    href: "https://www.openarmsps.org",
  },
  {
    label: "Pro Se Potential",
    href: "https://prosepotential.org/",
  },
  {
    label: "Rainier Valley Midwives",
    href: "https://www.myrvcc.org",
  },
  {
    label: "RESULTS",
    href: "https://results.org",
  },
  {
    label: "Sea Potential",
    href: "https://www.letsseapotential.com/",
  },
  {
    label: "Somali Family Safety Task Force",
    href: "https://www.somalifamilysafetytaskforce.org",
  },
  {
    label: "United Indians of All Tribes",
    href: "https://www.unitedindians.org",
  },
  {
    label: "Unkitawa",
    href: "https://unkitawa.org",
  },
  {
    label: "Urban Native Education Alliance",
    href: "https://www.urbannativeeducation.org/home-1",
  },
];

export const Partners = () => {
  return (
    <FullWidth>
      <div className="partners--root inner-column">
        <h2>Our partners</h2>
        <p>
          We have the honor of supporting many cultural communities,
          community-based organizations, and government agencies. Some of our
          recent partners include:
        </p>
        <ul className="partners--content">
          {list.map(({ label, href }) => (
            <li>
              <a href={href} target="_blank" rel="noreferrer">
                {label}
              </a>
            </li>
          ))}
        </ul>
      </div>
    </FullWidth>
  );
};
