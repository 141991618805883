import React, { useState } from "react";

import "./ContactForm.scss";

const eff = <span style={{ display: "none" }}>fudge the DOM</span>;

export const ContactForm = () => {
  const [mailTo, setMailTo] = useState(
    "mailto:RHL@@@Con@@@sul@@@tingteam@gmai@@@l.com"
  );
  return (
    <div className="contact-form--root inner-column" id="contact">
      <h2>Contact us!</h2>

      <div className="contact-form--secondary-text">
        Email us if you would like to discuss a collaboration. We look forward
        to hearing about your organization and ideas. <br />
        <a
          href={mailTo}
          onMouseEnter={() => setMailTo(mailTo.replaceAll("@@@", ""))}
          type="button"
          className="contact-form--submit"
        >
          RHLConsultingTeam{eff}@gma{eff}il<span>.</span>c<span>o</span>m
        </a>
      </div>
    </div>
  );
};
