import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { FullWidth } from "../fullWidth/FullWidth";
import "./Values.scss";

export const Item = ({ icon, title, content }) => {
  return (
    // These are returned in a fragment so they can be styled
    // with CSS grid.
    <>
      <div className="values--icon">{icon}</div>
      <div className="values--title">{title}</div>
      <div className="values--content">{content}</div>
    </>
  );
};

export const Values = () => {
  return (
    <FullWidth
      outerClassName="values--root"
      innerClassName="values--root-inner"
    >
      <h2>Our Values</h2>
      <div className="values--items">
        <Item
          // Note - intentionally using an en dash here.
          title="Community–driven"
          icon={
            // TODO this ain't right.
            <StaticImage
              src={"../../images/3_hands_icon.png"}
              aria-hidden
              alt=""
            />
          }
          content={
            <>
              We delight in the opportunity to serve people striving to uplift
              their communities and disrupt and improve systems. We love to be
              in community with others - as friends and as advocates - in
              service of their mission and goals, and as allies in their vision
              to create a better world.
            </>
          }
        />
        <Item
          title="Generosity of spirit"
          icon={
            <StaticImage
              src={"../../images/gift_icon.png"}
              aria-hidden
              alt=""
            />
          }
          content={
            <>
              We recognize that community leaders are acting generously when
              inviting us to support them. Accepting that honor, we give our
              time, resources, and attention generously.
            </>
          }
        />
        <Item
          title="Full integrity"
          icon={
            <StaticImage
              src={"../../images/scale_icon.png"}
              alt=""
              aria-hidden
            />
          }
          content={
            <>
              We have built our reputation by respecting the amazing leaders we
              work with, by honoring their work, experiences, and families, and
              by advocating for their missions. We work hard to be transparent
              and trustworthy.
            </>
          }
        />
      </div>
      <div className="values--icon-disclaimer">
        *Icons made by Freepik from @flaticon
      </div>
    </FullWidth>
  );
};
