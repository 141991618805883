import React from "react";
import { FullWidth } from "../fullWidth/FullWidth";
import "./Services.scss";

export const Item = ({ title, content }) => {
  return (
    <div className="services--grid-item">
      <h3 className="services--grid-item-title">{title}</h3>
      <div className="services--grid-item-content">{content}</div>
    </div>
  );
};

export const Services = () => {
  return (
    <FullWidth id="services" innerClassName="services--root" tabIndex={-1}>
      <div className="services--inner inner-column">
        <h2>Consulting services</h2>
        <div className="services--summary">
          We support the capacity of community-based organizations through
          management tools, coaching, and skill development, as they develop
          resilient networks and boldly improve outcomes for their communities.
        </div>
      </div>
      <div className="services--grid">
        <Item
          title="Leadership coaching"
          content={
            <>
              We coach leaders as they navigate the challenging waters of
              nonprofit leadership to build resilient communities.
            </>
          }
        />
        <Item
          title="Strategic plan and thinking"
          content={
            <>
              Using the Nonprofit Strategy Revolution toolkit, we collaborate to
              customize tools that drive strategic planning - articulating your
              mission, vision, values, strategic priorities, and actions.
            </>
          }
        />
        <Item
          title="Organizational development"
          content={
            <>
              We develop and customize organizational tools that improve your
              impact and facilitate leadership development.
            </>
          }
        />
        <Item
          title="Facilitation"
          content={
            <>
              We facilitate conversations to find common ground and identify
              strategic actions that magnify community impact and participatory
              leadership.
            </>
          }
        />
        <Item
          title="Fundraising"
          content={
            <>
              We help you raise funds - create a fundraising plan, launch an
              annual campaign, and write grant proposals.
            </>
          }
        />
        <Item
          title="Social media and communication"
          content={
            <>
              We develop websites, create social media tiles, develop graphics,
              and create strategic communications plans.
            </>
          }
        />
        <Item
          title="Race &amp; social justice"
          content={
            <>
              Our team recognizes that the organizations we work with already
              have the solutions to the issues that affect their communities. We
              coach leaders to lean into their cultural practices to build
              resilience and change systems.
            </>
          }
        />
      </div>
    </FullWidth>
  );
};
